<template>
<svg :width="width" :height="height" :fill="color" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
viewBox="0 0 386.258 386.258" style="enable-background:new 0 0 386.258 386.258;" xml:space="preserve">
<polygon points="96.879,0 96.879,386.258 289.379,193.129 "/>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
</svg>
</template>
<script>
export default {
  name: 'rightArrowIconSvg', // vue component name
  props: {
    color: {
      type: String,
      default: 'gray'
    },
    width: {
      type: String,
      default: '40px'
    },
    height: {
      type: String,
      default: '40px'
    }
  },
  data () {
    return {
    }
  }
}
</script>
