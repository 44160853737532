import Api from './API'
import {getCookies} from '@/utils/cookies'
// import { get } from 'http'
// const crypto = require('crypto')

function HRMModal () {
  if (!(this instanceof HRMModal)) {
    return new HRMModal()
  }
}

/* ***** *********** ************* **************** ******************* ****************** ****
*
*                                             HRM material Api
*
***** *********** ************* **************** ******************* ****************** **** */
/** ***********************HRM SETTINGS START HERE*********************************/

/** ***********************CONTACT TYPE******************************** */

HRMModal.prototype.getUserList = async function (
  start,
  length,
  searchVal,
  shortBy,
  sortOrder,
  isActive,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('authToken'))
  data.append('start', start)
  data.append('length', length)
  data.append('searchVal', searchVal)
  data.append('shortBy', shortBy)
  data.append('sortOrder', sortOrder)
  data.append('isActive', isActive)
  Api.getUserList(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}

HRMModal.prototype.addNewUser = async function (
  userDetail,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('authToken'))
  data.append('userDetail', JSON.stringify(userDetail))
  Api.addNewUser(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}


HRMModal.prototype.getUserDetail = async function (
  userDetailId,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('authToken'))
  data.append('userDetailId', userDetailId)
  Api.getUserDetail(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}


HRMModal.prototype.deleteUser = async function (
  userDetailId,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('authToken'))
  data.append('userDetailId', userDetailId)
  Api.deleteUser(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}
HRMModal.prototype.getSpecProjectDetail = async function (
  projectDetailId,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('authToken'))
  data.append('projectDetailId', projectDetailId)
  Api.getSpecProjectDetail(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}
HRMModal.prototype.checkEmailVal = async function (
  emailAddress,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('authToken'))
  data.append('emailAddress', emailAddress)
  Api.checkEmailVal(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}


HRMModal.prototype.getProjectList = async function (
  start,
  length,
  searchVal,
  shortBy,
  sortOrder,
  isActive,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('authToken'))
  data.append('start', start)
  data.append('length', length)
  data.append('searchVal', searchVal)
  data.append('shortBy', shortBy)
  data.append('sortOrder', sortOrder)
  data.append('isActive', isActive)
  Api.getProjectList(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}


HRMModal.prototype.addNewProject = async function (
  projectDetail,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('authToken'))
  data.append('projectDetail', JSON.stringify(projectDetail))
  Api.addNewProject(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}

HRMModal.prototype.getProjectPageList = async function (
  start,
  length,
  searchVal,
  shortBy,
  sortOrder,
  isActive,
  projectDetailId,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('authToken'))
  data.append('start', start)
  data.append('length', length)
  data.append('searchVal', searchVal)
  data.append('shortBy', shortBy)
  data.append('sortOrder', sortOrder)
  data.append('isActive', isActive)
  data.append('projectDetailId', projectDetailId)
  Api.getProjectPageList(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}



HRMModal.prototype.componentListing = async function (
  start,
  length,
  searchVal,
  shortBy,
  sortOrder,
  isActive,
  projectDetailId,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('authToken'))
  data.append('start', start)
  data.append('length', length)
  data.append('searchVal', searchVal)
  data.append('shortBy', shortBy)
  data.append('sortOrder', sortOrder)
  data.append('isActive', isActive)
  data.append('projectDetailId', projectDetailId)
  Api.componentListing(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}


HRMModal.prototype.inputFieldListing = async function (
  isActive,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('authToken'))
  data.append('isActive', isActive)
  Api.inputFieldListing(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}

HRMModal.prototype.addEditComponent = async function (
  componentDetail,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('authToken'))
  data.append('componentDetail', JSON.stringify(componentDetail))
  Api.addEditComponent(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}
HRMModal.prototype.addNewPage = async function (
  pageCreationDetail,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('authToken'))
  data.append('pageCreationDetail', JSON.stringify(pageCreationDetail))
  Api.addNewPage(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}


HRMModal.prototype.getCompDetail = async function (
  componentMasterId,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('authToken'))
  data.append('componentMasterId', componentMasterId)
  Api.getCompDetail(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}
HRMModal.prototype.getPagesDetail = async function (
  pageMasterId,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('authToken'))
  data.append('pageMasterId', pageMasterId)
  Api.getPagesDetail(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}
HRMModal.prototype.getContentDetail = async function (
  pageMasterId,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('authToken'))
  data.append('pageMasterId', pageMasterId)
  Api.getContentDetail(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}
HRMModal.prototype.savepageContant = async function (
  pageMasterId,
  pageContentDetail,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('authToken'))
  data.append('pageMasterId', pageMasterId)
  data.append('pageContentDetail', JSON.stringify(pageContentDetail))
  Api.savepageContant(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}
HRMModal.prototype.saveFieldName = async function (
  fieldDetail,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('authToken'))
  data.append('fieldDetail', JSON.stringify(fieldDetail))
  Api.saveFieldName(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}
HRMModal.prototype.removeFieldName = async function (
  fieldMasterId,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('authToken'))
  data.append('fieldMasterId',fieldMasterId)
  Api.removeFieldName(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}
HRMModal.prototype.duplicatePage = async function (
  projectDetailId,
  pageMasterId,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('authToken'))
  data.append('projectDetailId',projectDetailId)
  data.append('pageMasterId',pageMasterId)
  Api.duplicatePage(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}
HRMModal.prototype.pageActiveInactive = async function (
  pageMasterId,
  isActive,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('authToken'))
  data.append('pageMasterId',pageMasterId)
  data.append('isActive',isActive)
  Api.pageActiveInactive(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}
HRMModal.prototype.deleteSpecificPage = async function (
  projectDetailId,
  pageMasterId,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('authToken'))
  data.append('projectDetailId',projectDetailId)
  data.append('pageMasterId',pageMasterId)
  Api.deleteSpecificPage(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}
HRMModal.prototype.BlogPageList = async function (
  start,
  length,
  searchVal,
  shortBy,
  sortOrder,
  isActive,
  projectDetailId,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('authToken'))
  data.append('start',start)
  data.append('length',length)
  data.append('searchVal',searchVal)
  data.append('shortBy',shortBy)
  data.append('sortOrder',sortOrder)
  data.append('isActive',isActive)
  data.append('projectDetailId',projectDetailId)
  Api.BlogPageList(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}
HRMModal.prototype.BlogPageDetail = async function (
  blogPageMasterId,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('authToken'))
  data.append('blogPageMasterId',blogPageMasterId)
  Api.BlogPageDetail(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}
HRMModal.prototype.BlogPageActiveInactive = async function (
  blogPageMasterId,
  isActive,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('authToken'))
  data.append('blogPageMasterId',blogPageMasterId)
  data.append('isActive',isActive)
  Api.BlogPageActiveInactive(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}
HRMModal.prototype.BlogPageDelete = async function (
  blogPageMasterId,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('authToken'))
  data.append('blogPageMasterId',blogPageMasterId)
  Api.BlogPageDelete(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}
HRMModal.prototype.BlogPageAddUpdate = async function (
  reqDetail,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('authToken'))
  data.append('reqDetail', JSON.stringify(reqDetail))
  Api.BlogPageAddUpdate(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}

export default HRMModal
