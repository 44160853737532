import axios from 'axios'
import store from '@/store'
import * as config from '../../../config.js'
/**
 * init class API
 * @param opt
 * @returns {HrmApi}
 * @constructor
 */

function HrmApi (opt) {
  if (!(this instanceof HrmApi)) {
    return new HrmApi(opt)
  }
  opt = opt || {}
  this.classVersion = '1.0.0'
  this.api = axios.create({
    baseURL: config.API_ROOT,
    timeout: 9000000
  })
  this.api.interceptors.request.use(request => {
    store.dispatch('SetIsApiRunning', true)
    return request
  })
  this.api.interceptors.response.use(response => {
    store.dispatch('SetIsApiRunning', false)
    return response
  },
  error => {
    store.dispatch('SetIsApiRunning', false)
    return error
  })
}
/**
 * init class API
 * @param opt
 * @returns {HrmApi}
 * @constructor
 */

HrmApi.prototype._requestAuthPost = function (url, contentType, data, callback) {
  var headers = {
    Accept: 'application/json',
    'Authorization': 'Bearer 23432567654',
    'Content-Type': contentType
  }
  return this.api.post(url, data, headers).then(response => {
    if (response.data) {
      if (typeof callback === 'function') {
        callback()
      }
      return response.data
    } else {
      return {
        success: 0,
        message: response.problem
      }
    }
  })
}
HrmApi.prototype._requestDataPost = function (url, contentType, data, callback) {
  var headers = {
    Accept: 'application/json',
    'Content-Type': contentType
  }
  return this.api.post(url, data, headers).then(response => {
    if (response.data) {
      if (typeof callback === 'function') {
        callback()
      }
      return response.data
    } else {
      return {
        success: 0,
        message: response.problem
      }
    }
  }).catch(error => {
    return {
      success: 0,
      message: error.message
    }
  })
}
HrmApi.prototype._requestDataPostFileAttachment = function (url, contentType, data, callback) {
  var headers = {
    Accept: 'application/json',
    'Content-Type': contentType,
    responseType: 'blob'
  }
  return this.api.post(url, data, headers).then((response) => {
    if (response.data) {
      if (typeof callback === 'function') {
        callback()
      }
      return response.data
    } else {
      return {
        success: 0,
        message: response.problem
      }
    }
  }).catch(error => {
    return {
      success: 0,
      message: error.message
    }
  })
}
HrmApi.prototype._requestDataGet = function (url, contentType, data, callback) {
  var headers = {
    Accept: 'application/json',
    'Content-Type': contentType
  }
  var param = ''
  if (data !== '') {
    param = '/' + data
  }
  return this.api.get(url + param, headers).then(response => {
    if (response.data) {
      if (typeof callback === 'function') {
        callback()
      }
      return response.data
    } else {
      return {
        success: 0,
        message: response.problem
      }
    }
  }).catch(error => {
    return {
      success: 0,
      message: error.message
    }
  })
}
HrmApi.prototype._requestFileDataGet = function (url, contentType, data, callback) {
  var headers = {
    responseType: 'arraybuffer'
  }
  var param = ''
  if (data !== '') {
    param = '/' + data
  }
  return this.api.get(url + param, headers).then(response => {
    if (response.data) {
      if (typeof callback === 'function') {
        callback()
      }
      return response
    } else {
      return {
        success: 0,
        message: response.problem
      }
    }
  }).catch(error => {
    return {
      success: 0,
      message: error.message
    }
  })
}

// Employee List


HrmApi.prototype.getUserList = async function (data, callback) {
  const requestUrl = 'api/v1/user/list'
  const contentType = 'application/x-www-form-urlencoded'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
HrmApi.prototype.addNewUser = async function (data, callback) {
  const requestUrl = 'api/v1/user/add'
  const contentType = 'application/x-www-form-urlencoded'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
HrmApi.prototype.getUserDetail = async function (data, callback) {
  const requestUrl = 'api/v1/user/detail'
  const contentType = 'application/x-www-form-urlencoded'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
HrmApi.prototype.deleteUser = async function (data, callback) {
  const requestUrl = 'api/v1/user/delete'
  const contentType = 'application/x-www-form-urlencoded'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
HrmApi.prototype.getProjectList = async function (data, callback) {
  const requestUrl = 'api/v1/project/list'
  const contentType = 'application/x-www-form-urlencoded'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
HrmApi.prototype.addNewProject = async function (data, callback) {
  const requestUrl = 'api/v1/project/save'
  const contentType = 'application/x-www-form-urlencoded'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
HrmApi.prototype.getSpecProjectDetail = async function (data, callback) {
  const requestUrl = 'api/v1/project/detail'
  const contentType = 'application/x-www-form-urlencoded'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
HrmApi.prototype.getProjectPageList = async function (data, callback) {
  const requestUrl = 'api/v1/page/list'
  const contentType = 'application/x-www-form-urlencoded'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
HrmApi.prototype.checkEmailVal = async function (data, callback) {
  const requestUrl = 'api/v1/user/checkEmailExist'
  const contentType = 'application/x-www-form-urlencoded'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
HrmApi.prototype.componentListing = async function (data, callback) {
  const requestUrl = 'api/v1/component/list'
  const contentType = 'application/x-www-form-urlencoded'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
HrmApi.prototype.inputFieldListing = async function (data, callback) {
  const requestUrl = 'api/v1/inputField/list'
  const contentType = 'application/x-www-form-urlencoded'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
HrmApi.prototype.addEditComponent = async function (data, callback) {
  const requestUrl = 'api/v1/component/save'
  const contentType = 'application/x-www-form-urlencoded'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
HrmApi.prototype.getCompDetail = async function (data, callback) {
  const requestUrl = 'api/v1/component/detail'
  const contentType = 'application/x-www-form-urlencoded'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
HrmApi.prototype.getPagesDetail = async function (data, callback) {
  const requestUrl = 'api/v1/page/detail'
  const contentType = 'application/x-www-form-urlencoded'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
HrmApi.prototype.addNewPage = async function (data, callback) {
  const requestUrl = 'api/v1/page/save'
  const contentType = 'application/x-www-form-urlencoded'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
HrmApi.prototype.getContentDetail = async function (data, callback) {
  const requestUrl = 'api/v1/page/content/detail'
  const contentType = 'application/x-www-form-urlencoded'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
HrmApi.prototype.savepageContant = async function (data, callback) {
  const requestUrl = 'api/v1/page/content/save'
  const contentType = 'application/x-www-form-urlencoded'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
HrmApi.prototype.saveFieldName = async function (data, callback) {
  const requestUrl = 'api/v1/inputField/save'
  const contentType = 'application/x-www-form-urlencoded'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
HrmApi.prototype.removeFieldName = async function (data, callback) {
  const requestUrl = 'api/v1/inputField/delete'
  const contentType = 'application/x-www-form-urlencoded'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
HrmApi.prototype.duplicatePage = async function (data, callback) {
  const requestUrl = 'api/v1/page/copy'
  const contentType = 'application/x-www-form-urlencoded'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
HrmApi.prototype.pageActiveInactive = async function (data, callback) {
  const requestUrl = 'api/v1/page/activeInActive'
  const contentType = 'application/x-www-form-urlencoded'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
HrmApi.prototype.deleteSpecificPage = async function (data, callback) {
  const requestUrl = 'api/v1/page/delete'
  const contentType = 'application/x-www-form-urlencoded'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
// BLOG HERE..........
HrmApi.prototype.BlogPageList = async function (data, callback) {
  const requestUrl = 'api/v1/blog/pageList'
  const contentType = 'application/x-www-form-urlencoded'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
HrmApi.prototype.BlogPageDetail = async function (data, callback) {
  const requestUrl = 'api/v1/blog/pageDetail'
  const contentType = 'application/x-www-form-urlencoded'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
HrmApi.prototype.BlogPageActiveInactive = async function (data, callback) {
  const requestUrl = 'api/v1/blog/pageActiveInActive'
  const contentType = 'application/x-www-form-urlencoded'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
HrmApi.prototype.BlogPageDelete = async function (data, callback) {
  const requestUrl = 'api/v1/blog/pageDelete'
  const contentType = 'application/x-www-form-urlencoded'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
HrmApi.prototype.BlogPageAddUpdate = async function (data, callback) {
  const requestUrl = 'api/v1/blog/addUpdate'
  const contentType = 'application/x-www-form-urlencoded'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}

export default HrmApi
